import React, { useEffect } from "react";
import "../styles.scss";
import { Carousel } from "antd";
import { useNavigate } from "react-router-dom";
const TENANT = process.env.REACT_APP_TENANT;

const AuthLayout = ({ children }) => {
  const navigate = useNavigate();
  const layoutClass = TENANT === "ABUJA" ? "auth-abuja-left-pain" : "auth-left-pain"

  useEffect(() => {
    const token = localStorage.getItem("dvla_token");
    if (token) {
      navigate("/dashboard");
    }
  }, [navigate]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="lg:flex">
        <div className="hidden xl:max-w-screen-sm lg:flex lg:w-1/2 h-screen">
          <Carousel autoplay>
            <div className={layoutClass}></div>
            <div className={layoutClass}></div>
            <div className={layoutClass}></div>
            <div className={layoutClass}></div>
          </Carousel>
        </div>
        <div className="flex-1 auth-container relative">
          {/* <div className="absolute top-10 right-10 hover:shadow-xl cursor-pointer shadow-lg py-2 px-4 text-labels rounded-xl transition ease-in-out duration-300">
            <span className="text-gray-500">Don’t have an account?</span>{" "}
            <span
              onClick={() => navigate("/dashboard")}
              className="font-bold hover:text-link-hover text-link-active"
            >
              Sign Up
            </span>
          </div> */}
          {children}
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
